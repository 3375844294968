body {
  margin: 0;
  font-family: "Inter", system-ui;
  background: #fff;
  color: #000;
  position: relative;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1130px;
  padding: 0 15px;
  margin: 0 auto;
  
}

.section__title {
  color: #1F6946;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    @include media("<=810px") {
      
    }
        @include media("<=430px") {
          font-size: 30px;
        }
}

.wrapper {
  
}

//BURGER BUTTON//
.burger__button {
  display: none;

  @include media("<=840px") {
    display: block;
    position: fixed;
    top: 30px;
    right: 40px;
    z-index: 200;
  }

  @include media("<=430px") {
    top: 30px;
    right: 20px;
  }

  @include media("<=840px") {
    &.active {
      position: fixed;
      top: 38px;
      right: 40px;
      z-index: 200;
    }

    @include media("<=430px") {
      &.active {
        top: 36px;
        right: 20px;
      }
    }
  }

  .line {
    height: 4px;
    width: 50px;
    border-radius: 4px;
    margin-bottom: 6px;
    background-color: #171717;
    transition: all 200ms ease-in-out;
    opacity: 1;

    @include media("<=736px") {
      height: 4px;
      width: 48px;
    }
  }

  &.active {
    .line {
      background-color: #171717;

      &.one {
        transform: rotate(45deg) ;
      }

      &.two {
        transform: rotate(-45deg)  translateY(-7px) translateX(7px);

        @include media("<=736px") {
          transform: rotate(-45deg)  translateY(-7px) translateX(7px);
        }
      }

      &.three {
        opacity: 0;
      }
    }
  }
}

.page {
  padding-bottom: 100px;
}

.page__inner {
  display: flex;
  align-items: center;
    flex-direction: column;
    row-gap: 20px;
    font-size: 16px;
    font-weight: 700;
    p {
      max-width: 430px;
    }
}


