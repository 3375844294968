.gallery {
    padding: 0 0 130px;
    @include media("<=550px") {
        padding-bottom: 80px;
    }
}


.gallery__inner {
    padding: 0 0 75px;
    display: flex;
    justify-content: center;
    column-gap: 35px;
    row-gap: 49px;
    flex-wrap: wrap;
    @include media("<=1440px") {
        
    }
        @include media("<=550px") {
            flex-direction: column;
            align-items: center;
            row-gap: 34px;
        }
}

.gallery__item {
    max-width: 360px;
    width: 100%;
    flex: 0 1 31%;
    @include media("<=1200px") {
       flex: 0 1 30%;
    }
        @include media("<=550px") {
            max-width: 300px;
        }
    img {
        width: 100%;
        display: block;
    }
}

.gallery__subtitle {
    text-align: center;
        font-size: 56px;
        font-weight: 700;
        margin: 0 auto 75px;
        @include media("<=450px") {
            font-size: 32px;
        }

}

.link {
    display: block;
    margin: 0 auto;
    max-width: 313px;
    @include media("<=450px") {
        max-width: 200px;
    }
    img {
        width: 100%;
            display: block;
    }
}
