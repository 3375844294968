.info {
  
}

.info__inner {
  width: 100%;
  border-radius: 30px;
    background: #23734D;
    padding: 68px 15px;
    display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 20px;
      margin-bottom: 100px;
}

.info__text {
  font-size: 20px;
  font-weight: 700;
  max-width: 795px;
  color: #fff;
}

.info__subtitle {
  font-size: 32px;
  font-weight: 700;
  margin: 0 auto;
  max-width: 839px;
  text-align: center;
  @include media("<=450px") {
    font-size: 24px;
  }
}
