.features {
  position: relative;
  padding: 75px 0 100px;
  overflow: hidden;
  
  @include media("<=1440px") {
    
  }
  
}

.features__inner {
  padding: 50px 0 75px;
  display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    z-index: 3;
    @include media("<=1440px") {
      

    }
        @include media("<=1080px") {
          
        }
                @include media("<=740px") {
                   flex-direction: column;
                }
        @include media("<=500px") {
          
        }
}

.features__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 343px;
  min-height: 39px;
  flex: 0 1 48%;
  border-radius: 100px;
    background: #23734D;
  padding: 10px;
  text-align: center;
  color: #fff;
    font-size: 16px;
    font-weight: 700;
  transition: all 0.8s ease 0.2s;
  @include media("<=1440px") {
    
  }
  @include media("<=1080px") {
 
  }
  
  @include media("<=740px") {
      height: auto;
      flex: 0 1 100%;
      width: 100%;
  }
  @include media("<=450px") {
    
  }
 @include media("<=360px") {
   

 }
  
}

.features__text {
  color: #fff;
    font-size: 16px;
 font-weight: 700;
@include media("<=1080px") {
   
  }
  @include media("<=810px") {
    

  }

  @include media("<=430px") {
    font-size: 14px;
  }
}

.features__subtitle {
  text-align: center;
  font-size: 16px;
    font-weight: 700;
    max-width: 360px;
    margin: 0 auto;
}
