.hero {
  padding: 100px 0 150px;

  position: relative;
//  z-index: 2;
  overflow-x: clip;
  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
   
    
  }
  @include media("<=430px") {
   padding: 80px 0 100px;
  }
  
}

.hero__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  @include media("<=1080px") {
    margin: 0 auto;
  }
  @include media("<=810px") {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 30px;
  }
  @include media("<=430px") {
    
  }
  @include media("<=375px") {
  }
  @include media("<=320px") {
  }
}


.hero__title {
  max-width: 667px;
  width: 100%;
  opacity: 0;
  transition: all 0.8s ease 0.2s;
  
    &._active {
      opacity: 1;
    }
        @include media("<=1440px") {
         
        }
  @include media("<=1280px") {
    
  }

  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
   

    
  }
  @include media("<=500px") {
    
  }

  @include media("<=410px") {
   
  }
  &-games {
   max-width: 830px;
  }
  &-about {
    max-width: 709px;
  }
  &-contacts {
    max-width: 833px;
  }
  &-policy {
    max-width: 788px;
  }
}

.hero__text {
  max-width: 603px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  @include media("<=450px") {
    font-size: 16px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  color: #fff;
  background-color: transparent;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  padding: 7px;
  max-width: 120px;
  border: 1px #0E61C4 solid;
  width: 100%;
  border-radius: 30px;
    background: #0E61C4;
  transition: all ease-in-out 0.3s;
  position: relative;
  z-index: 25;
  &:hover {
    background-color: #fff;
    color: #171717;
  }
    @include media("<=1440px") {
      
    }
        @include media("<=810px") {
         
        }
    @include media("<=430px") {
      
    }
        @include media("<=375px") {
          
        }
  
 
}





