.footer {
border-top: 2px solid #000;
padding: 40px 0;
    @include media("<=430px") {

    }
}

.footer__inner {
    justify-content: space-between;
    align-items: center;
        width: 100%;
        display: flex;
        align-items: flex-start;
    position: relative;
    @include media("<=640px") {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 20px;
    }
}

.footer__title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
}

.footer__nav {
    display: flex;
    flex-direction: column;
        row-gap: 10px;

        @include media("<=640px") {
                flex-direction: column;
                align-items: flex-start;
                row-gap: 15px;
                width: 100%;
                padding: 10px 0;
            }
}

.footer__link {
    color: #171717;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.footer__social {
    color: #171717;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 10px;
    &:hover {
        text-decoration: underline;
    }
}



