.about {
    padding: 0 0 100px;
    @include media("<=500px") {
        padding-bottom: 80px;
    }
}

.about__inner {
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
}

.about__text {
    max-width: 430px;
}

