.header {
  width: 100%;
  position: relative;
  padding: 32px 0 0;
  @include media("<=1080px") {
    
  }
  @include media("<=840px") {
    
  }
  @include media("<=430px") {
    
  }
 
}

.header__inner {
  
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 30;
  row-gap: 10px;
  @include media("<=1080px") {
  
      margin: 0 auto;
    }
  @include media("<=840px") {
    
  }
}

.header__title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  @include media("<=740px") {
   font-size: 28px;
  }
     @include media("<=450px") {
        font-size: 20px;
     }
}

.header__nav {
  flex: 0 1 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 50px;
  @include media("<=1080px") {
    
  }
@include media("<=450px") {
  column-gap: 15px;
}
  
}

.header__link {
  font-size: 18px;
  text-decoration: none;
  color: #000;
  transition: all 0.3s ease;
  position: relative;
  @include media("<=810px") {}
    @include media("<=450px") {
      font-size: 16px;
    }
  &:hover {
    
  }
}

.header__link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.1em;
  background: #171717;
  bottom: -6px;
  left: 0;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.header__link:hover::after {
  transform: scale(1, 1);
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  max-width: 34px;
  width: 100%;
  flex: 0 1 100%;
  z-index: 21;
  @include media("<=1440px") {
    
  }
    @include media("<=1080px") {
    
    }
  @include media("<=650px") {
   display: none;
    
  }
    @include media("<=430px") {
      
    }
  img {
    width: 100%;
    display: block;
  }
  
}

