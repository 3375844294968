.contacts {
    padding: 0 0 245px;
    @include media("<=900px") {
        padding-bottom: 80px;
    }

}

.contacts__inner {
    display: flex;
    align-items: center;
    justify-content: space-around;

    @include media("<=1200px") {}

    @include media("<=900px") {
        flex-direction: column;
        row-gap: 40px;
    }

    @include media("<=810px") {}
}

.contacts__content {
    flex: 0 1 100%;
    max-width: 393px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

}

.contacts__image {
    width: 100%;
    img {
        width: 100%;
    }
        
    
}

.contacts__form {
    flex: 0 1 100%;
        max-width: 390px;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
 

    @include media("<=1200px") {
        padding-top: 0;
    }

    @include media("<=430px") {
       
    }
}

.form__input {
    width: 100%;

    input {

        border-radius: 50px;
            background: #F0F0F0;
        width: 100%;
        font-size: 14px;
        color: #6F6F6F;
        padding: 19px 32px;
        border: none;
        font-family: "Inter", system-ui;

        @include media("<=430px") {}
    }

    textarea {
        resize: none;
        border-radius: 20px;
            background: #F0F0F0;
            width: 100%;
            font-size: 14px;
            color: #6F6F6F;
            padding: 19px 32px;
            border: none;
            font-family: "Inter", system-ui;
    }

}

.contacts__text {
    font-size: 16px;
    font-weight: 700;
    padding-top: 40px;
    p {
        padding-bottom: 10px;
    }
}





