.games {
    padding: 0 0 100px;
}

.games__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 90px;
    column-gap: 16px;
   
    @include media("<=500px") {
        flex-direction: column;
        align-items: center;
        row-gap: 30px;
    }
}

.games__item {
    display: flex;
    flex-direction: column;
        overflow: hidden;
        width: 100%;
        max-width: 355px;
  flex: 0 1 31%;
@include media("<=1080px") {
    flex: 0 1 30%;
}
@include media("<=840px") {
    flex: 0 1 48%;
    
}
}

.games__image {
    position: relative;
    max-width: 355px;
    width: 100%;
    margin-bottom: 20px;
    @include media("<=840px") {
            
        }
    img {
        width: 100%;
        display: block;
    }
}


.games__content {
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    flex: 0 1 100%;

    @include media("<=840px") {
            
        }
}



.games__title {
    
    font-size: 18px;
    font-weight: 700;
    p {
        padding-top: 5px;
        color: #6E6E6E;
        font-size: 10px;
    }
}

.games__text {
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 30px;
    @include media("<=1080px") {
    
    }
}

